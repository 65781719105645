<template>
  <div>
    <div style="margin-bottom: 16px;">
      <a-page-header title="主机" :ghost="false" :sub-title="detail.name">
        <template slot="tags">
          <boolean-status-badge :value="detail.monitored"></boolean-status-badge>
        </template>
        <template v-if="permission.update" slot="extra">
          <a @click="$refs.updateForm.show(detail.id)" style="color: #faad14;">
            <a-space style="user-select: none;">
              <a-icon type="edit"></a-icon>
              <span>编辑</span>
            </a-space>
          </a>
          <update-form ref="updateForm" @ok="fetch"></update-form>
        </template>

        <a-descriptions :column="{ xs: 1, sm: 2, xl: 3, xxl: 4 }">
          <a-descriptions-item label="系统类型">
            <a-tooltip :title="detail.os_version">
              <a-tag color="blue">{{ $t(`os_type.${detail.os_type}`) }}</a-tag>
            </a-tooltip>
          </a-descriptions-item>
          <a-descriptions-item label="CPU">{{ `${detail.cpu} 核` }}</a-descriptions-item>
          <a-descriptions-item label="内存">{{ `${detail.memory} G` }}</a-descriptions-item>
          <a-descriptions-item label="监控地址">{{ detail.monitor_address }}</a-descriptions-item>
          <a-descriptions-item label="所属项目">{{ detail.project ? detail.project.name : '-' }}</a-descriptions-item>
        </a-descriptions>
      </a-page-header>
    </div>

    <a-card :active-tab-key="activeTabKey" :bordered="false" :tab-list="tabList" @tabChange="key => { this.activeTabKey = key }">
      <history-tab v-show="activeTabKey === 'history'" :os-type="detail.os_type"  :sourceId="$route.params.id"></history-tab>
      <alert-event-tab v-show="activeTabKey === 'alertEvent'" :source-id="$route.params.id" source-type="host"></alert-event-tab>
      <process-tab v-show="activeTabKey === 'process'"></process-tab>
      <port-tab v-show="activeTabKey === 'port'"></port-tab>
    </a-card>
  </div>
</template>

<script>
import { getHost } from '@/api/host'
import BooleanStatusBadge from '@/components/badge/BooleanStatusBadge'
import AlertEventTab from '@/components/tab/AlertEventTab'
import { hasPermission } from '@/utils'
import UpdateForm from '../modules/UpdateForm'
import HistoryTab from './modules/HistoryTab'
import PortTab from './modules/PortTab'
import ProcessTab from './modules/ProcessTab'

export default {
  name: 'HostDetail',
  components: {
    AlertEventTab,
    BooleanStatusBadge,
    HistoryTab,
    PortTab,
    ProcessTab,
    UpdateForm
  },
  data () {
    return {
      detail: {
        project: {},
        os_type: 'unknown'
      },
      activeTabKey: 'history',
      tabList: [
        { key: 'history', tab: '监控数据' },
        { key: 'alertEvent', tab: '告警事件' },
        { key: 'process', tab: '进程' },
        { key: 'port', tab: '端口' }
      ]
    }
  },
  computed: {
    permission () {
      return {
        update: hasPermission('host.update')
      }
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      getHost(this.$route.params.id).then(res => {
        this.detail = res.data
      })
    }
  }
}
</script>

<template>
  <a-modal title="配置指标" :width="1080" :visible="visible" @ok="handleOk" @cancel="handleCancel" class="item-modal">

    <a-form-model :model="form" ref="form">
      <a-row :gutter="16">
        <a-col :xs="24" :sm="12" :xl="12">
          <a-form-model-item prop="name">
            <a-input v-model="form.name" :allow-clear="true" :max-length="128" placeholder="请输入指标"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :xs="24" :sm="12" :xl="12">
          <a-form-model-item>
            <a-button icon="search" type="primary" @click="search">查询</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>

    <a-table :columns="columns" :data-source="options" :loading="loading" :pagination="false" row-key="key"
      :scroll="{ x: 700 }" :row-selection="rowSelection" style="margin-bottom: 16px;">
      <template slot="name" slot-scope="record">{{ record }}</template>
      <template slot="value_type" slot-scope="record">{{ $t('value_type.' + record) }}</template>
      <template slot="last_value" slot-scope="record">
        <a-tooltip overlayClassName="myTooltip" placement="topLeft" :title="record">
          {{ record }}
        </a-tooltip>
      </template>
    </a-table>
    <div style="height:30px">
      <a-pagination v-model="form.page" :pageSize="form.page_size" :show-size-changer="true"
        :show-total="t => `共 ${t} 条`" :total="total" @change="page => { this.form.page = page; this.fetch() }"
        @showSizeChange="(_, pageSize) => { this.form.page = 1; this.form.page_size = pageSize; this.fetch() }"
        style="float: right;"></a-pagination>
    </div>
  </a-modal>
</template>

<script>
import { getHostMonitorItemList, updateHost } from '@/api/host'
import { getDatabaseMonitorItemList, updateDatabase } from '@/api/database'
import { getMiddlewareMonitorItemList, updateMiddleware } from '@/api/middleware'

export default {
  name: 'ItemModal',
  props: { assetType: { type: String } },
  data () {
    return {
      visible: false,
      options: [],
      selectedOptions: [],
      id: '',
      form: { page: 1, page_size: 10 },
      total: 0,
      loading: false,
      columns: [
        { dataIndex: 'name', title: '指标名称', width: 280, scopedSlots: { customRender: 'name' } },
        { dataIndex: 'value_type', title: '指标类型', width: 150, scopedSlots: { customRender: 'value_type' } },
        { dataIndex: 'last_value', title: '最新值', width: 200, ellipsis: true, scopedSlots: { customRender: 'last_value' } }
      ],
      selectedData: [],
      rowSelection: {
        selectedRows: [],
        selectedRowKeys: [],
        onChange: (selectedRowKeys, selectedRows) => {
          this.rowSelection.selectedRowKeys = selectedRowKeys
          this.rowSelection.selectedRows = selectedRows
          if (this.selectedData.length) {
            this.selectedData = this.MergeArray(this.selectedData, this.rowSelection.selectedRows)
          } else {
            this.selectedData = selectedRows
          }
          const arr = []
          this.selectedData.forEach(item => {
            arr.push({ label: item.name || item.label, key: item.key, value_type: item.value_type })
          })
          this.selectedData = arr
        }
      }
    }
  },
  methods: {
    fetch () {
      this.loading = true
      switch (this.assetType) {
        case 'middleware':
          getMiddlewareMonitorItemList(this.id, this.form).then(res => {
            const data = res.data
            this.options = data.data
            this.total = data.total
          }).finally(() => {
            this.loading = false
          })
          break
        case 'database':
          getDatabaseMonitorItemList(this.id, this.form).then(res => {
            const data = res.data
            this.options = data.data
            this.total = data.total
          }).finally(() => {
            this.loading = false
          })
          break
        default:
          getHostMonitorItemList(this.id, this.form).then(res => {
            const data = res.data
            this.options = data.data
            this.total = data.total
          }).finally(() => {
            this.loading = false
          })
          break
      }
    },
    show (id, selectData) {
      this.id = id
      this.fetch()
      selectData.forEach(ele => {
        this.rowSelection.selectedRowKeys.push(ele.key)
      })
      this.selectedData = selectData
      this.visible = true
    },
    handleOk () {
      switch (this.assetType) {
        case 'database':
          updateDatabase(this.id, { preferred_monitor_items: this.selectedData })
          break
        case 'middleware':
          updateMiddleware(this.id, { preferred_monitor_items: this.selectedData })
          break
        default:
          updateHost(this.id, { preferred_monitor_items: this.selectedData })
          break
      }
      this.$emit('selectItems', this.selectedData)
      this.form.page = 1
      this.form.page_size = 10

      this.reset()
      this.rowSelection.selectedRowKeys = []
    },
    handleCancel () {
      this.form.page = 1
      this.form.page_size = 10
      this.rowSelection.selectedRowKeys = []
      this.reset()
    },
    reset () {
      this.form = { page: 1, page_size: 10 }
      this.visible = false
      this.$refs.form.resetFields()
    },
    search () {
      this.form.page = 1
      this.fetch()
    },
    MergeArray (arr1, arr2) {
      let arr = [...arr1]
      for (const item of arr2) {
        if (arr1.findIndex(i => i.key === item.key) === -1) {
          arr.push(item)
        }
      }
      arr = this.SpliceArray(arr, this.rowSelection.selectedRowKeys)
      return arr
    },
    SpliceArray (arr1, arr2) {
      const arr = []
      for (const item of arr1) {
        if (arr2.findIndex(i => i === item.key) !== -1) {
          arr.push(item)
        }
      }
      return arr
    }
  }
}
</script>

<style lang="less">
.item-modal {
  .ant-select {
    width: 85%;
  }
}

.myTooltip {
  max-width: 500px;
  max-height: 200px;
  overflow: hidden;

  .ant-tooltip-inner {
    max-width: 500px;
    max-height: 200px;
    overflow: scroll;
  }
}
</style>

<template>
  <div>
    <a-button :disabled="disabled" type="primary" style="margin-bottom: 16px;" @click="click">获取</a-button>

    <a-table
      :columns="columns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="false"
      row-key="pid"
      :row-selection="rowSelection"
      :scroll="{ x: scrollX }"
      style="margin-bottom: 16px;"
    >
      <template slot="command" slot-scope="record">
        <a-tooltip placement="topLeft" :title="record">{{ record }}</a-tooltip>
      </template>
    </a-table>

    <div style="height: 32px;">
      <span v-if="rowSelection.selectedRowKeys.length" style="line-height: 32px;">{{ `已选中 ${rowSelection.selectedRowKeys.length} 条` }}</span>
    </div>
  </div>
</template>

<script>
import { getHostPortList } from '@/api/host'

export default {
  name: 'PortTab',
  data () {
    return {
      disabled: false,
      columns: [
        { dataIndex: 'pid', title: 'PID', width: 100, fixed: 'left' },
        { dataIndex: 'name', title: '名称', width: 200 },
        { dataIndex: 'protocol', title: '协议', width: 200 },
        { dataIndex: 'local_address', title: '本地地址', width: 400, ellipsis: true, scopedSlots: { customRender: 'command' } },
        { dataIndex: 'state', title: '状态', width: 100 }
      ],
      dataSource: [],
      total: 0,
      loading: false,
      rowSelection: {
        selectedRowKeys: [],
        onChange: selectedRowKeys => {
          this.rowSelection.selectedRowKeys = selectedRowKeys
        }
      }
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => { sum += column.width })
      return sum
    }
  },
  methods: {
    click () {
      this.disabled = true
      this.loading = true
      getHostPortList(this.$route.params.id).then(res => {
        this.dataSource = res.data.data
      }).finally(() => {
        this.disabled = false
        this.loading = false
      })
    }
  }
}
</script>
